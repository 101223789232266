import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge bg-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args))),
    style: {"cursor":"pointer"}
  }, [
    _createElementVNode("td", null, [
      (_ctx.isCurrentUser)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "YOU"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", null, _toDisplayString(_ctx.email), 1)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", null, _toDisplayString(_ctx.userRole), 1)
    ])
  ]))
}