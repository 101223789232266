import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-100 w-100 loading-wrapper" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      active: !_ctx.isLoaded,
      isErrored: _ctx.isErrored,
      "is-full-page": false
    }, null, 8, ["active", "isErrored"]),
    _createElementVNode("div", {
      id: _ctx.chartId,
      class: "w-100 h-100"
    }, null, 8, _hoisted_2)
  ]))
}