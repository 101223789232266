
    import moment from 'moment';

    import { defineComponent } from 'vue'

    // jquery
    import $ from "jquery";

    export default defineComponent({
        props: ['selected', 'id', 'subject', 'timestamp', 'message', 'read'],
        emits: ['toggleSelected','markRead'],
        computed: {
            elapsedTime(): string {
                return moment(this.timestamp).fromNow();
            }
        },
        methods: {
            toggleExpand() {
                $(`#${this.id}`).toggle();
                if(!this.read) {
                    this.$emit('markRead');
                }
            }
        }
    });

