// Borrowed from: https://github.com/jnt0r/vue-auth0-plugin

import { App } from 'vue';
import createAuth0Client, { Auth0ClientOptions } from '@auth0/auth0-spa-js';
import AuthProperty from './AuthProperty';
import Plugin from './plugin';
import AuthenticationGuard from './AuthenticationGuard';

export default {
    install(app: App, options: Auth0ClientOptions): void {
        // global $auth property is deprecated
        app.config.globalProperties.$auth = Plugin.properties;
        app.provide<AuthProperty>('auth', Plugin.properties);
        app['auth'] = Plugin.properties;

        createAuth0Client(options).then((client) => Plugin.initialize(app, client));
    },
};

const AuthenticationState = Plugin.state;
const AuthenticationProperties = Plugin.properties;

export { AuthenticationGuard, AuthenticationState, AuthenticationProperties };
