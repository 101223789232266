
    import { defineComponent } from 'vue'
    import { LoadingState } from './helpers';
    import MenuBar from './components/menu/index.vue';

    export default defineComponent({
        name: 'App',
        components: {
            'menu-bar': MenuBar
        },
        computed: {
            isLoading(): boolean {
                return this.$auth.accountSwitching || this.$user.loadingState == LoadingState.Loading
            }
        }
    });

