/* Adapted from https://github.com/alexsabdev/vue-drift */

import { App } from 'vue';

let installed = false;

const load = (appId: string) => {

    if (installed) return;

    const script = document.createElement('script');
    script.innerText = `!function(){var t=window.driftt=window.drift=window.driftt||[];if(!t.init){if(t.invoked)return void(window.console&&console.error&&console.error("Drift snippet included twice."));t.invoked=!0,t.methods=["identify","config","track","reset","debug","show","ping","page","hide","off","on"],t.factory=function(e){return function(){var n=Array.prototype.slice.call(arguments);return n.unshift(e),t.push(n),t}},t.methods.forEach(function(e){t[e]=t.factory(e)}),t.load=function(t){var e=3e5*Math.ceil(new Date/3e5),n=document.createElement("script");n.type="text/javascript",n.async=!0,n.crossorigin="anonymous",n.src="https://js.driftt.com/include/"+e+"/"+t+".js";var o=document.getElementsByTagName("script")[0];o.parentNode.insertBefore(n,o)}}}(),drift.SNIPPET_VERSION="0.3.1",drift.load("${appId}"),drift.on('ready',function(api){ api.widget.hide() }),drift.on('chatClose',function(){ drift.api.widget.hide() })`;
    script.async = true;
    document.body.appendChild(script);

    (window as any).drift.config({
        // locale: 'en-US',
         messages: {
          welcomeMessage: 'Hey there!',
           awayMessage: "Hey, we're not here right now, but leave a message.",
        //    emailCaptureMessage: "Leave your email so we can get back to you.",
           thankYouMessage: "Thanks we'll follow up soon."
         },
         enableWelcomeMessage: true,
         enableCampaigns: true,
         enableChatTargeting: true,
        //  backgroundColor: '#000000',
        //  foregroundColor: '#ffffff',
        //  autoAssignee: {
        //    name: 'Trevor Rundell',
        //    title: 'Engineer',
        //    email: 'trevor@example.com',
        //    avatarUrl: 'https://s.gravatar.com/avatar/...',
        //  },
        //  inboxId: 99999,
        //  cookieDomain: '.example.com',
        //  disableNewConversations: true
       })

    installed = true;
}

async function identifyUser(app: App) : Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).drift.identify(app.auth.userId, {email: app.auth.user?.email});
}

export interface DriftOptions {
    appId: string;
    development: boolean;
}

export default {
    install(app: App, options: DriftOptions): void {

        const { appId, development } = options;

        if (development) return;

        if (!appId) throw new Error('Drift Plugin missing the "appId" option');
        if (!window) throw new Error('Drift Plugin missing the "window" object');

        load(appId);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const drift = () => (window as any).drift;

        app.config.globalProperties.$chat = new Proxy(drift, {
            get(target, prop) {
                const drift = target();

                if (!drift) throw new Error('[vue-drift] missing the "drift" object');

                if (prop == "toggle") { return drift.api.toggleChat; }
                if (prop == "open") { return drift.api.openChat; }

                if (prop in drift) return drift[prop];

                throw new Error(`[vue-drift] missing the "${prop.toString()}" method`);
            }
        });

        app.emitter.on('login-complete', () => identifyUser(app));
        app.provide('chat', drift);
    },
};




