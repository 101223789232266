import { defineComponent } from "vue";

import Highcharts from 'highcharts';
import HighchartsData from 'highcharts/modules/data';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsHeatmap from 'highcharts/modules/heatmap';

HighchartsData(Highcharts); // Initialise
HighchartsExporting(Highcharts);
HighchartsHeatmap(Highcharts);

Highcharts.setOptions({
    colors: ['#abd9df', '#a5d0bf', '#ffe5a2'],
});

export { Highcharts as Charting }

interface ChartFilter {
    blah: boolean
}

export { ChartFilter }

import { LoadingState } from '@/helpers';

interface ChartData {
    loadingState: LoadingState,
    errorMessage: null | string,
    chartData: undefined | [],
    chart: undefined | Highcharts.Chart,
    chartFilter: undefined | ChartFilter,
    aspectRatioPercentage: string,
    chartId: string,
}

export { ChartData }

import { v4 as newUuid } from "uuid";

const VueChartBase = defineComponent({
    props: {
        parentFilter: { type: Object as () => ChartFilter },
    },
    data():ChartData {
        return {
            loadingState: LoadingState.Loading,
            errorMessage: null,
            aspectRatioPercentage: '75%',
            chartData: undefined,
            chart: undefined,
            chartFilter: this.parentFilter,
            chartId: 'chart-' + newUuid(),
        };
    },
    computed: {
        isLoaded(): boolean {
            return this.loadingState == LoadingState.Loaded;
        },
        isErrored(): boolean {
            return this.loadingState == LoadingState.Errored;
        }
    },
    watch: {
        chartData(): void {
            this.updateChart();
        },
        parentFilter: {
            handler(newVal: ChartFilter) {
                this.chartFilter = newVal;
                this.fetchData();
            },
            deep: true
        }
    },
    mounted() {
        this.createChart();
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData(): void {
            throw new Error('not implemented');
        },
        createChart(): void {
            throw new Error('not implemented');
        },
        async updateChart(): Promise<void> {
            const data = this.chart?.data as Highcharts.Data;
            const dataOptions: Highcharts.DataOptions = {
                rows: this.chartData
            };
            data.update(dataOptions, true);
        }
    }
});

export { VueChartBase }