import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args))),
    style: {"cursor":"pointer"}
  }, [
    _createElementVNode("td", null, [
      _createElementVNode("i", {
        class: _normalizeClass(['fab', _ctx.brandIcon])
      }, null, 2)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", null, _toDisplayString(_ctx.expiryMonth), 1)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", null, _toDisplayString(_ctx.expiryYear), 1)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", null, _toDisplayString(_ctx.last4Numbers), 1)
    ])
  ]))
}