
    import { defineComponent } from 'vue';
    import { LoadingState } from '@/helpers';
    import RowTemplate from './table-row.vue';
    import axios from 'axios';

    type UserAccounts = {
        accountId: string,
        userAccountName: string
    }[];

    interface Data {
        loadingState: LoadingState,
        errored: boolean,
        errorMessage: null | string,
        userAccounts: null | UserAccounts
    }

    export default defineComponent({
        components: {
            'table-row': RowTemplate
        },
        data(): Data {
            return {
                loadingState: LoadingState.Loading,
                errored: false,
                errorMessage: null,
                userAccounts: null
            };
        },
        created() {
            this.fetchData();
            this.emitter.on('account-changed', () => this.fetchData());
        },
        computed: {
            isLoaded(): boolean {
                return this.loadingState == LoadingState.Loaded;
            },
            isErrored(): boolean {
                return this.loadingState == LoadingState.Errored;
            }
        },
        methods: {
            fetchData(): void {
                this.userAccounts = null;
                this.loadingState = LoadingState.Loading;

                axios

                    .get('/user/accounts')
                    .then(response => {
                        this.userAccounts = response.data as UserAccounts
                        this.loadingState = LoadingState.Loaded;
                    })
                    .catch(error => {
                        this.loadingState = LoadingState.Errored;
                        this.errorMessage = error.message;
                        console.log(error);
                    });
            }
        },
    });
