import '@fortawesome/fontawesome-pro/js/all.js';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'vue-loading-overlay/dist/vue-loading.css';
import './global.scss';

import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);

// Events
import mitt, { Emitter, EventType } from 'mitt';
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.emitter = emitter;

// Routes

import Overview from '@/components/overview/index.vue';
import Templates from '@/components/template/index.vue';
import Loupes from '@/components/loupe/index.vue';
import User from '@/components/user/index.vue';
import UserSettings from '@/components/user/settings/index.vue';
import UserCards from '@/components/user/cards/index.vue';
import UserAccounts from '@/components/user/accounts/index.vue';
import Account from '@/components/account/index.vue';
import AccountUsage from '@/components/account/usage/index.vue';
import AccountIntegration from '@/components/account/integration/index.vue';
import AccountSubscriptions from '@/components/account/subscriptions/index.vue';
import AccountUsers from '@/components/account/users/index.vue';
import AccountInvites from '@/components/account/invites/index.vue';
import Notifications from '@/plugins/notifications/notifications.vue';

const routes = [
    { path: '/sequence', name: 'sequences', component: Templates },
    { path: '/message', name: 'messages', component: Templates },
    { path: '/template', name: 'templates', component: Templates },
    { path: '/template/:id}', name: 'template-detail', component: Templates },
    { path: '/loupe', name: 'snapshots', component: Loupes },
    { path: '/notifications', name: 'notifications', component: Notifications },
    {
        path: '/user', name: 'user', component: User, redirect: { name: 'user-settings' }, children: [
            { path: 'settings', name: 'user-settings', component: UserSettings },
            { path: 'cards', name: 'user-cards', component: UserCards },
            { path: 'accounts', name: 'user-accounts', component: UserAccounts },
        ]
    },
    {
        path: '/account', name: 'account', component: Account, redirect: { name: 'account-usage' }, children: [
            { path: 'usage', name: 'account-usage', component: AccountUsage },
            { path: 'integration', name: 'account-integration', component: AccountIntegration },
            { path: 'subscriptions', name: 'account-subscriptions', component: AccountSubscriptions },
            { path: 'users', name: 'account-users', component: AccountUsers },
            { path: 'accounts', name: 'account-invites', component: AccountInvites },
        ]
    },
    { path: '', name: 'overview', component: Overview }
    /*{ path: '/:pathMatch(.*)*', redirect: { name: 'overview' } }*/
];

// TODO: We need a page that Auth0 can redirect to that doesn't trigger another redirect - assuming that's why we're double auth'ing

import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
});

app.use(router);

// Security
import AuthProperty from '@/plugins/auth/AuthProperty';
import VueAuth0Plugin, { AuthenticationGuard } from '@/plugins/auth';

app.use(VueAuth0Plugin, {
    domain: process.env.AUTH_DOMAIN,
    client_id: process.env.AUTH_CLIENT_ID,
    redirect_uri: process.env.AUTH_REDIRECT_URI,
    audience: 'https://api.loupey.io'
});

// Slap the auth guard over all routes

router.beforeEach(AuthenticationGuard);

// Chat

import ChatPlugin from "./plugins/drift";
app.use(ChatPlugin, { appId: process.env.DRIFT_APP_ID });


// Api

import axios from 'axios';
import VueAxios from 'vue-axios';

// Assume that all client-side calls will be to our own endpoint and therefore require the token to be set
axios.interceptors.request.use(function (config) {
    return app.auth?.getTokenSilently({
        audience: 'https://api.loupey.io',
        scope: `account:${ app.auth?.accountId ?? '' }`
    }).then(function (token: string) {
        config.headers = { Authorization: `Bearer ${token}` };
        return config;
    });
});

axios.defaults.baseURL = process.env.API_BASE_URL;

app.use(VueAxios, axios);

// Loading animations
import Loading from './components/loading';
app.component('loading', Loading);

//app.emitter.on('*', (type) => console.log(`Event: ${ String(type) }`));

// Accounts
import UserPlugin, { UserProperty } from '@/plugins/user';
app.use(UserPlugin);

// Notifications
import NotificationsPlugin, { NotificationsProperty } from '@/plugins/notifications';
app.use(NotificationsPlugin);

// Script Loader
import LoadScript from "vue-plugin-load-script";
app.use(LoadScript);


app.mount('#app');

// Add TS support for our plugins
declare module "@vue/runtime-core" {
    export interface ComponentCustomProperties {
        emitter: Emitter<Record<EventType, unknown>>;
        auth: AuthProperty;
        user: UserProperty;
        notifications: NotificationsProperty;

        $emitter: Emitter<Record<EventType, unknown>>;
        $auth: AuthProperty;
        $user: UserProperty;
        $notifications: NotificationsProperty;
    }
    export interface App {
        emitter: Emitter<Record<EventType, unknown>>;
        auth: AuthProperty;
        user: UserProperty;
        notifications: NotificationsProperty;
    }
}