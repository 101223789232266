
    import { defineComponent } from 'vue'

    export default defineComponent({
        props: ['inviteId', 'currentUserId', 'fromUserId', 'fromEmail', 'toEmail', 'userRole'],
        computed: {
            isCurrentUser(): boolean {
                return this.fromUserId == this.currentUserId;
            },
        }
    });


