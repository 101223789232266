

    import { defineComponent } from "vue";
    import axios from "axios";

    export default defineComponent({
        components: {
        },
        data() {
            return {
                filterSettings: { blah: true }
            };
        },
        methods: {
            toggleFilter(): void {
                this.filterSettings.blah = !this.filterSettings.blah;
            },
            testCall(): void {
                axios
                    .get(`/accounts/${ this.auth.accountId }/userrole`)
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    });

