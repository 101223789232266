

    import { defineComponent } from "vue";
    import FirstChart from "./first-chart.vue";
    import ResponseTimeChart from "./response-time.vue";
    import DayTimeImpactChart from "./day-time-impact.vue";

    export default defineComponent({
        name: "App",
        components: {
            FirstChart,
            ResponseTimeChart,
            DayTimeImpactChart,
        },
        data() {
            return {
                filterSettings: { blah: true }
            };
        },
        methods: {
            refresh(): void {
                this.filterSettings.blah = !this.filterSettings.blah;
            }
        },
        created() {
            this.emitter.on('account-changed', () => this.refresh());
        }
    });


