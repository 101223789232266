
    import { defineComponent } from 'vue';
    import { LoadingState } from '@/helpers';
    import axios, { AxiosError } from 'axios';

    interface Data {
        loadingState: LoadingState,
        errorMessage: null | string,
        email: null | string,
        role: null | string,
        visible: boolean
    }

    export default defineComponent({
        data(): Data {
            return {
                loadingState: LoadingState.Loading,
                errorMessage: null,
                email: null,
                role: null,
                visible: false
            };
        },
        computed: {
            isLoaded(): boolean {
                return this.loadingState == LoadingState.Loaded;
            },
            isErrored(): boolean {
                return this.loadingState == LoadingState.Errored;
            }
        },
        methods: {
            inviteUser() {
                this.loadingState = LoadingState.Loading;

                axios

                    .post('/account/invites', { toEmail: this.email, role: this.role })
                    .then(() => {
                        this.loadingState = LoadingState.Loaded;
                    })
                    .catch((error: Error | AxiosError ) => {
                        this.loadingState = LoadingState.Errored;

                        if (axios.isAxiosError(error) && error.response)  {
                            this.errorMessage = error.response.data;
                        } else {
                            this.errorMessage = error.message;
                        }

                        console.log(error);
                    })
                
            },
            show() {
                this.visible = true;
            },
            hide() {
                this.visible = false;
            }

        },
    });
