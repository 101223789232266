
    import { defineComponent } from 'vue'

    export default defineComponent({
        props: ['brand', 'expiryMonth', 'expiryYear', 'last4Numbers'],
        computed: {
            brandIcon(): string {
                switch (this.brand) {
                    case "visa": return "fa-cc-visa";
                    case "mastercard": return "fa-cc-mastercard";
                    default: return "";
                }
            }
        }
    });


