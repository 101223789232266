
    import { defineComponent } from 'vue'

    export default defineComponent({
        props: ['currentUserId','userId', 'email', 'userRole'],
        computed: {
            isCurrentUser(): boolean {
                return this.userId == this.currentUserId;
            },
        }
    });


