
    import moment from 'moment';

    import { defineComponent } from 'vue'

    export default defineComponent({
        props: ['id', 'name', 'totalMessages', 'lastUsed'],
        computed: {
            lastUsedDuration(): string {
                return moment(this.lastUsed).fromNow();
            },
            totalMessagesFormatted(): string {
                return formatNumber(this.totalMessages);
            }
        },
        methods: {
            navigate() {
                this.$router.push('overview');
            }
        }
    });

    var ranges = [
        { divider: 1e12, suffix: 'T' },
        { divider: 1e9, suffix: 'B' },
        { divider: 1e6, suffix: 'M' },
        { divider: 1e3, suffix: 'k' }
    ];

    function formatNumber(n: number):string {
        if (n < 0) {
            return '-' + formatNumber(-n);
        }
        for (var i = 0; i < ranges.length; i++) {
            if (n >= ranges[i].divider) {
                return (n / ranges[i].divider).toFixed(1).toString() + ranges[i].suffix;
            }
        }
        return n.toString();
    }

