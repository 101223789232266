
    import { defineComponent } from 'vue';
    import { LoadingState } from '@/helpers';
    import MessageTemplate from './table-row.vue';
    import axios from 'axios';

    type Templates = {
        lastUsed: string
    }[];

    interface Data {
        loadingState: LoadingState,
        errored: boolean,
        errorMessage: null | string,
        templates: null | Templates
    }

    export default defineComponent({
        components: {
            'template-table-row': MessageTemplate
        },
        data(): Data {
            return {
                loadingState: LoadingState.Loading,
                errored: false,
                errorMessage: null,
                templates: null
            };
        },
        created() {
            // fetch the data when the view is created and the data is
            // already being observed
            this.fetchData();
        },
        watch: {
            // call again the method if the route changes
            //'$route': 'fetchData'
        },
        computed: {
            isLoaded(): boolean {
                return this.loadingState == LoadingState.Loaded;
            },
            isErrored(): boolean {
                return this.loadingState == LoadingState.Errored;
            }
        },
        methods: {
            fetchData(): void {
                this.templates = null;
                this.loadingState = LoadingState.Loading;

                axios

                    /*.get('api/template')*/
                    .get('/templates')
                    .then(response => {
                        this.templates = response.data as Templates
                        this.loadingState = LoadingState.Loaded;
                    })
                    .catch(error => {
                        this.loadingState = LoadingState.Errored;
                        this.errorMessage = error.message;
                        console.log(error);
                    })
            }
        },
    });
