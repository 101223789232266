
    import { defineComponent } from 'vue';
    import { LoadingState } from '../../../helpers';
    import axios from 'axios';

    type PlatformOption = {
        id: string,
        name: string,
        backgroundColour: string,
        authoriseUrl: string
    };

    const platforms: PlatformOption[] = [
        {
            id: "mailchimp",
            name: "Mailchimp",
            backgroundColour: "#ffe01b",
            authoriseUrl: `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${ process.env.MAILCHIMP_CLIENT_ID }&redirect_uri=${ process.env.MAILCHIMP_REDIRECT_URI }`
        },
        {
            id: "klaviyo",
            name: "Klaviyo",
            backgroundColour: "#53a970",
            authoriseUrl: ""
        }
    ]

    interface Mailchimp { 
        serverPrefix: string,
        accountName: string,
        id: string
    }
    interface Integration {
        platform: string,
        isAuthorised: boolean,
        //retentionMonths: number,
        mailchimp: Mailchimp
    }
    interface Account {
        id: string,
        integration: Integration
    }

    interface Data {
        loadingState: LoadingState,
        errored: boolean,
        errorMessage: null | string,
        platforms: PlatformOption[],
        account: null | Account,
        authCode: null | string
    }

    function getAuthCode(): null | string {
        const p = new URLSearchParams(window.location.search); 
        return p.get('code');
    }

    export default defineComponent({
        data(): Data {
            return {
                loadingState: LoadingState.Loading,
                errored: false,
                errorMessage: null,
                account: null,
                platforms: platforms,
                authCode: null
            };
        },
        created() {

            this.authCode = getAuthCode();

            if(this.authCode != null)
            {
                this.loadingState = LoadingState.Loading;
                this.processAuthRedirect();
            }
            else
            {
                this.loadingState = LoadingState.Loaded;
            }

            this.fetchData();
        },
        methods: {
            fetchData(): void {
                this.account = null;
                this.loadingState = LoadingState.Loading;

                axios

                    .get('/account')
                    .then(response => {
                        this.account = response.data as Account
                        this.loadingState = LoadingState.Loaded;
                    })
                    .catch(error => {
                        this.loadingState = LoadingState.Errored;
                        this.errorMessage = error.message;
                        console.log(error);
                    })
            },
            processAuthRedirect(): void {

                axios
                    .post('/account/integration/mailchimp/exchangeoauthcode', { accessCode: this.authCode })
                    .then(response => {
                        this.authCode = null;
                        this.loadingState = LoadingState.Loaded;
                        this.$router.push({ path: '/account/integration' });
                    })
                    .catch(error => {
                        this.loadingState = LoadingState.Errored;
                        this.errorMessage = error.message;
                        console.log(error);
                    })
            }
        },
        computed: {
            isHandlingCallback(): boolean {
                return this.authCode != null;
            },
            isLoaded(): boolean {
                return this.loadingState == LoadingState.Loaded;
            },
            isErrored(): boolean {
                return this.loadingState == LoadingState.Errored;
            },
            isAuthorised(): boolean {
                if(this.account) {
                    return this.account.integration.isAuthorised;
                }
                return false;
            },
            platformId: {
                get(): string {
                    return this.account?.integration.platform ?? this.platforms[0].id;
                },
                set(newValue: string) {
                    if(this.account)
                    {
                        this.account.integration.platform = newValue;
                    }
                }
            },
            platformDetails(): PlatformOption {
                // return this.platforms[0];
                return platforms.find(p => p.id == this.platformId) ?? this.platforms[0];
            }
        }
    });
