

    import { Modal } from 'bootstrap'
    import { defineComponent } from 'vue';
    import { LoadingState } from '@/helpers';
    import axios, { AxiosError } from 'axios';

    import $ from 'jquery';

    interface Data {
        loadingState: LoadingState,
        errorMessage: null | string,
        email: null | string,
        role: null | string,
        modalElem: null | Modal 
    }

    export default defineComponent({
        data(): Data {
            return {
                loadingState: LoadingState.Loading,
                errorMessage: null,
                email: null,
                role: null,
                modalElem: null
            };
        },
        mounted() {
            let myModalEl = this.$refs.inviteUserModal as Element;
            this.modalElem = Modal.getOrCreateInstance(myModalEl);
        },
        computed: {
            isLoaded(): boolean {
                return this.loadingState == LoadingState.Loaded;
            },
            isErrored(): boolean {
                return this.loadingState == LoadingState.Errored;
            }
        },
        methods: {
            inviteUser() {
                this.loadingState = LoadingState.Loading;

                axios

                    .post('/account/invites', { toEmail: this.email, role: this.role })
                    .then(() => {
                        this.loadingState = LoadingState.Loaded;
                        this.hide();
                        this.clear();
                    })
                    .catch((error: Error | AxiosError ) => {
                        this.loadingState = LoadingState.Errored;

                        if (axios.isAxiosError(error) && error.response)  {
                            this.errorMessage = error.response.data;
                        } else {
                            this.errorMessage = error.message;
                        }

                        console.log(error);
                    })
                
            },
            clear() {
                this.email = null;
                this.role = "User";
                this.errorMessage = null;
            },
            show() {
                this.modalElem?.show();
            },
            hide() {
                this.modalElem?.hide();
                $(document.body).removeClass("modal-open");
                $(".modal-backdrop").remove();
            }

        },
    });
