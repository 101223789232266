import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args))),
    style: {"cursor":"pointer"}
  }, [
    _createElementVNode("td", null, [
      _createElementVNode("div", null, _toDisplayString(_ctx.id), 1)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", null, _toDisplayString(_ctx.status), 1)
    ])
  ]))
}