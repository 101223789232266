
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: 'dots',
        props: {
            errorMessage: {
                type: String,
                default: 'Unknown'
            },
        },
        methods: {
            requestRetry() {
                this.$emit('retryRequested');
            }
        }
    });
