

    import { defineComponent } from "vue";
    import { VueChartBase, Charting } from './chart';
    import { LoadingState } from '../../helpers';
    import axios from "axios";

    export default defineComponent({
        mixins: [VueChartBase],
        methods: {
            async fetchData(): Promise<void> {
                this.loadingState = LoadingState.Loading;

                axios
                    .get('/data/responsetime')
                    .then(response => {
                        this.chartData = response.data as [];
                        this.loadingState = LoadingState.Loaded;
                    })
                    .catch(error => {
                        this.loadingState = LoadingState.Errored;
                        this.errorMessage = error.message;
                        console.log(error);
                    });
            },
            createChart(): void {
                this.chart = Charting.chart({
                    chart: {
                        renderTo: this.chartId,
                        type: 'column',
                        height: this.aspectRatioPercentage,
                        style: {
                            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans- serif'
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: 'Elapsed Engagement Time'
                    },
                    data: {
                        rows: []
                    },
                    xAxis: {
                        type: 'category',
                        title: undefined,
                    },
                    yAxis: [
                        {
                            title: undefined,
                        },
                        {
                            title: undefined,
                            max: 100,
                            opposite: true,
                        }
                    ],
                    series: [
                        {
                            type: 'column',
                            yAxis: 0,
                        },
                        {
                            type: 'spline',
                            yAxis: 1,
                        }
                    ],
                    plotOptions: {
                        series: {
                            borderWidth: 1,
                            shadow: false,
                        }
                    },
                });
            }
        }
    });

