
    import { defineComponent } from 'vue';
    import { LoadingState } from '../../../helpers';

    interface AccountUsage {
        integration: string,
        users: number,
        retentionMonths: number,
        allowance: {
            contacts: number,
            events: number
        },
        usage: {
            contacts: number,
            events: number
        },
        lastSyncTimestamp: Date,
        latestEventTimestamp: Date
    }

    interface Data {
        loadingState: LoadingState,
        errored: boolean,
        errorMessage: null | string,
        accountUsage: null | AccountUsage,
    }

    export default defineComponent({
        data(): Data {
            return {
                loadingState: LoadingState.Loading,
                errored: false,
                errorMessage: null,
                accountUsage: {
                    integration: "mailchimp",
                    users: 5,
                    retentionMonths: 12,
                    usage: {
                        contacts: 12345,
                        events: 23456
                    },
                    allowance: {
                        contacts: 15000,
                        events: 25000
                    },
                    lastSyncTimestamp: new Date(),
                    latestEventTimestamp: new Date()
                }
            };
        },
        computed: {
            integrationIconClasses(): string {
                return "fab fa-mailchimp";
            },
            integrationBackgroundColor(): string {
                return "#ffe01b";
            }
        }
    });
