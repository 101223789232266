
    import { defineComponent } from 'vue';
    import { LoadingState } from '../../helpers';
    import { Notification } from './';
    import NotificationRowTemplate from './table-row.vue';

    export default defineComponent({
        name: 'notifications',
        components: {
            'template-table-row': NotificationRowTemplate
        },
        computed: {
            notifications(): Notification[] {
                return this.$notifications.notifications;
            },
            numSelected(): number {
                return this.$notifications.notifications.filter(n => n.selected).length;
            },
            numUnread(): number {
                return this.$notifications.notifications.filter(n => !n.read).length;
            }
        }
    });
