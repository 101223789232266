

    import { defineComponent } from 'vue';
    import { LoadingState } from '@/helpers';
    import RowTemplate from './table-row.vue';
    import axios from 'axios';

    type Subscriptions = {
        id: string,
        status: string,
    }[];

    type ApprovedCard = {
        id: string,
    };

    interface Data {
        loadingState: LoadingState,
        errored: boolean,
        errorMessage: null | string,
        subscriptions: null | Subscriptions
    }

    export default defineComponent({
        components: {
            'table-row': RowTemplate
        },
        /*mixins: [PaymentMixin],*/
        data(): Data {
            return {
                loadingState: LoadingState.Loading,
                errored: false,
                errorMessage: null,
                subscriptions: null
            };
        },
        created() {
            this.fetchData();
            this.emitter.on('account-changed', () => this.fetchData());
        },
        computed: {
            isLoaded(): boolean {
                return this.loadingState == LoadingState.Loaded;
            },
            isErrored(): boolean {
                return this.loadingState == LoadingState.Errored;
            }
        },
        methods: {
            async fetchData(): Promise<void> {

                this.subscriptions = null;
                this.loadingState = LoadingState.Loading;

                await axios

                    .get('/billing/subscriptions')
                    .then(response => {
                        this.subscriptions = response.data as Subscriptions;
                        this.loadingState = LoadingState.Loaded;
                    })
                    .catch(error => {
                        this.loadingState = LoadingState.Errored;
                        this.errorMessage = error.message;
                        console.log(error);
                    })
            }
        },
    });
