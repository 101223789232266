

    import { defineComponent } from 'vue';
    import { LoadingState } from '@/helpers'
    import RowTemplate from './table-row.vue';
    import axios from 'axios';

    import { loadScript } from "vue-plugin-load-script";

    type StoredCards = {
        id: string,
        name: string,
        expiryMonth: string,
        expiryYear: string,
        last4Numbers: string
    }[];

    type ApprovedCard = {
        id: string,
    };

    interface Data {
        loadingState: LoadingState,
        errored: boolean,
        errorMessage: null | string,
        cards: null | StoredCards,
        stripe: null | StripeClient
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cardElement: null | any
    }

    declare interface StripeClient {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        elements(): any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        createPaymentMethod(type: string, card: Element): Promise<any>
    }

    declare function Stripe(publicApiKey: string): StripeClient;

    export default defineComponent({
        components: {
            'table-row': RowTemplate
        },
        data(): Data {
            return {
                loadingState: LoadingState.Loading,
                errored: false,
                errorMessage: null,
                cards: null,
                stripe: null,
                cardElement: null
            };
        },
        created() {
            this.fetchData();

            loadScript("https://js.stripe.com/v3/")
                .then(() => {
                    this.stripe = Stripe(process.env.STRIPE_PUBLIC_API_KEY);
                    var elements = this.stripe.elements();
                    this.cardElement = elements.create('card');
                    this.cardElement.mount('#card-element');
                })
                .catch(error => {
                    this.loadingState = LoadingState.Errored;
                    this.errorMessage = error.message;
                    console.log(error);
                });

        },
        computed: {
            isLoaded(): boolean {
                return this.loadingState == LoadingState.Loaded;
            },
            isErrored(): boolean {
                return this.loadingState == LoadingState.Errored;
            }
        },
        methods: {
            async createCard(): Promise<void> {

                await this.checkCard()
                    .then(id =>
                        this.addCard(id)
                            .then(() => this.fetchData())
                        );

            },
            async checkCard(): Promise<string> {

                if (this.stripe == null) throw new Error("stripe client not initialised");

                return await this.stripe?.createPaymentMethod('card', this.cardElement)
                    .then(result => {
                        let id: string = result.paymentMethod.id;
                        console.log(result);
                        console.log(`Stripe Token: ${id}`);
                        return id;
                    })
                    .catch(error => {
                        console.log(error);
                        throw error;
                    });
            },
            async addCard(id: string): Promise<void> {

                return await axios

                    .post('/billing/Cards', { id: id } as ApprovedCard)
                    .then(response => {
                        console.log(response);
                        return;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            async fetchData(): Promise<void> {

                this.cards = null;
                this.loadingState = LoadingState.Loading;

                await axios

                    .get('/billing/cards')
                    .then(response => {
                        this.cards = response.data as StoredCards;
                        this.loadingState = LoadingState.Loaded;
                    })
                    .catch(error => {
                        this.loadingState = LoadingState.Errored;
                        this.errorMessage = error.message;
                        console.log(error);
                    })
            }
        },
    });
