import { App, reactive } from 'vue';
import UserAccountPicker from "./user-account-picker.vue";
import { LoadingState } from '@/helpers';
import axios from 'axios';

export type UserAccounts = {
    accountId: string,
    userAccountName: string
}[];

export interface UserProperty {
    loadingState: LoadingState,
    errorMessage: null | string,
    userAccounts: UserAccounts,
    switchAccount: (accountId: string) => Promise<void>
}

async function switchAccount(accountId: string): Promise<void> {
    await appRef.auth.swapAccount(accountId);
    appRef.emitter.emit("account-changed");
}

const properties = reactive({
    loadingState: LoadingState.Loading,
    errorMessage: null,
    userAccounts: [],
    switchAccount
}) as UserProperty;

let appRef: App;

async function populateUserAccounts() : Promise<void> {

    properties.loadingState = LoadingState.Loading;

    try {

        const accountId = appRef.auth.accountId;
        const accountName = "";

        if(accountId && accountName) {
            properties.userAccounts = [
                {
                    accountId: accountId,
                    userAccountName: accountName
                }
            ]
        }

        await axios
            .get('/user/accounts')
            .then(response => {
                properties.userAccounts = response.data as UserAccounts
                properties.loadingState = LoadingState.Loaded;
            })
            .catch(error => {
                properties.loadingState = LoadingState.Errored;
                properties.errorMessage = error.message;
                console.log(error);
            });

        if (properties.userAccounts.length > 0 && !appRef.auth.accountId) {
            console.log('No default account set...');
            await appRef.auth.swapAccount(properties.userAccounts[0].accountId);
        }

        properties.loadingState = LoadingState.Loaded;
    }
    catch (e) {
        console.error(e);
        properties.loadingState = LoadingState.Errored;
    }
}

export default {
    install: (app: App):void  => {

        appRef = app;

        app.config.globalProperties.$user = properties;
        app.provide<UserProperty>('user', properties);
        app['user'] = properties;

        app.emitter.on('login-complete', populateUserAccounts);

        app.component("user-account-picker", UserAccountPicker);
    },
};