import { App, reactive } from 'vue';
import NotificationsList from "./notifications.vue";
import { LoadingState } from '@/helpers';
import axios from 'axios';

export type Notification = {
    selected: boolean,
    id: string,
    notificationType: string,
    subject: string,
    message: string,
    timestamp: Date,
    read: boolean
};

export interface NotificationsProperty {
    loadingState: LoadingState,
    errorMessage: null | string,
    notifications: Notification[],
    markRead: (notificationId: string) => Promise<void>
}

async function markRead(notificationId: string): Promise<void> {
    appRef.emitter.emit("notification-read");
}

const properties = reactive({
    loadingState: LoadingState.Loading,
    errorMessage: null,
    notifications: [],
    markRead: markRead
}) as NotificationsProperty;

let appRef: App;
let continuationToken: string | null = null;

async function populateNotifications() : Promise<void> {

    properties.loadingState = LoadingState.Loading;

    try {

        // await axios
        //     .get('/api/notifications')
        //     .then(response => {
        //         properties.notifications = response.data as Notification[];
        //         properties.loadingState = LoadingState.Loaded;
        //         continuationToken = 'blah';
        //     })
        //     .catch(error => {
        //         properties.loadingState = LoadingState.Errored;
        //         properties.errorMessage = error.message;
        //         console.log(error);
        //     });

        properties.notifications = [
            {
                selected: false,
                id: "blah",
                subject: "Notification subject",
                message: "Slightly longer text that would be the full detail of a notification - possibly including some HTML...",
                notificationType: "",
                timestamp: new Date(),
                read: false
            },
            {
                selected: false,
                id: "blahblah",
                subject: "Notification subject",
                message: "Slightly longer text that would be the full detail of a notification - possibly including some HTML...",
                notificationType: "",
                timestamp: new Date(),
                read: false
            }
        ];

        continuationToken = 'blah';

        properties.loadingState = LoadingState.Loaded;
    }
    catch (e) {
        console.error(e);
        properties.loadingState = LoadingState.Errored;
    }
}

export default {
    install: (app: App):void  => {

        appRef = app;

        app.config.globalProperties.$notifications = properties;
        app.provide<NotificationsProperty>('notifications', properties);
        app['notifications'] = properties;

        app.emitter.on('login-complete', populateNotifications);

        app.component("notifications", NotificationsList);
    },
};