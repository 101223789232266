import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["value", "disabled"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    id: "accountSelect",
    class: "form-select form-select-sm",
    value: _ctx.selectedAccountId,
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.switchAccount && _ctx.switchAccount(...args))),
    disabled: _ctx.isLoading
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userAccounts, (userAccount) => {
      return (_openBlock(), _createElementBlock("option", {
        value: userAccount.accountId,
        key: userAccount.accountId
      }, _toDisplayString(userAccount.userAccountName), 9, _hoisted_2))
    }), 128))
  ], 40, _hoisted_1))
}