
    import { defineComponent } from 'vue';
    import { LoadingState } from '../../helpers';
    import { UserAccounts } from '.';

    export default defineComponent({
        name: 'user-account-picker',
        computed: {
            userAccounts(): UserAccounts {
                return this.$user.userAccounts;
            },
            isLoading(): boolean {
                return this.$user.loadingState == LoadingState.Loading;
            },
            selectedAccountId(): string | undefined {
                return this.$auth.accountId;
            }
        },
        methods: {
            // Don't judge me!
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            switchAccount: function (event: any) {
                this.$user.switchAccount(event.target.value);
            }
        },
    });
