

    import { defineComponent } from "vue";
    import { VueChartBase, Charting } from './chart';
    import { LoadingState } from '../../helpers';
    import axios from "axios";

    export default defineComponent({
        mixins: [VueChartBase],
        methods: {
            async fetchData(): Promise<void> {
                this.loadingState = LoadingState.Loading;

                axios
                    .get('/data/daytimeimpact')
                    .then(response => {
                        this.chartData = response.data as [];
                        this.loadingState = LoadingState.Loaded;
                    })
                    .catch(error => {
                        this.loadingState = LoadingState.Errored;
                        this.errorMessage = error.message;
                        console.log(error);
                    });
            },
            createChart(): void {
                this.chart = Charting.chart({
                    chart: {
                        renderTo: this.chartId,
                        type: 'heatmap',
                        height: this.aspectRatioPercentage,
                        plotBorderWidth: 2,
                        style: {
                            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans- serif'
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: 'Engagement Day and Time'
                    },
                    data: {
                        rows: []
                    },
                    xAxis: {
                        type: 'category',
                    },
                    yAxis: {
                        type: 'category',
                        title: undefined,
                        categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                        reversed: true
                    },
                    colorAxis: {
                        min: 0,
                        minColor: '#abd9df',
                        maxColor: '#326e89',
                    },
                });
            }
        }
    });

