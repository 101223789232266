
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'dots',
  props: {
    color: {
      type: String,
      default: '#000'
    },
    height: {
      type: Number,
      default: 240
    },
    width: {
      type: Number,
      default: 60
    }
  }
})
