

    import { defineComponent } from 'vue';
    //import AuthProperty from '@/plugins/auth/AuthProperty';

    //declare module '@vue/runtime-core' {
    //    export interface ComponentCustomProperties {
    //        $auth: AuthProperty
    //    }
    //}

    export default defineComponent({
        name: 'menu-bar',
        methods: {
            // Log the user in
            login() {
                this.$auth.loginWithRedirect();
            },
            // Log the user out
            logout() {
                this.$auth.logout({
                    returnTo: window.location.origin
                });
            }
        },
        computed: {
            numUnreadNotifications(): number {
                return this.$notifications.notifications.filter(n => !n.read).length;
            }
        }
    });

