

    import { defineComponent } from 'vue';
    import Dots from '../loaders/dots.vue';
    import Error from '../loaders/error.vue';

    export default defineComponent({
        name: 'loading',
        props: {
            active: Boolean,
            isFullPage: {
                type: Boolean,
                default: true
            },
            isErrored: {
                type: Boolean,
                defaut: false
            },
            errorMessage: {
                type: String,
                default: 'Unknown'
            },
        },
        data() {
            return {
                // Don't mutate the prop
                isActive: this.active
            }
        },
        components: { Dots, Error },
        methods: {
            requestRetry() {
                this.$emit('retryRequested');
            }
        },
        watch: {
            active(value) {
                this.isActive = value
            },
        },
        computed: {
        },
    });

